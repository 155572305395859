/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const sportingHero = {
  title: `Sporting goods store POS`,
  subtext:
    "Up your game with the all-in-one POS system for sporting goods stores that makes it quick and easy to sell in-store and online from a single platform.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/retail/sporting-goods/demo",
  },
};
export const sportingSubHero = {
  title: "Simplify yours sales",
  list: [
    "Attract more customers and accelerate growth with an integrated POS & ecommerce solution ",
    "Save time with one integrated catalog for in-store and online",
    "Sell your products all in one place with powerful search, filtering, and sorting ",
    "Reach more customers online with a custom ecommerce storefront and built-in tools for shipping and local delivery",
    "Offer more ways to collect payments, including contactless",
  ],
  subtext:
    "As a sporting goods store, you encounter a wide range of customers and customer expectations, so it’s important to be available both in-store and online to meet their needs. That’s where SpotOn comes in. We have everything you need to manage your shop, expand your reach, and have happier customers—all in one easy-to-use platform",
  ctaPrime: {
    ctaTitle: "Learn more",
    ctaTarget: "/retail/sporting-goods/demo",
  },
};

export const sportingWhiteGlove = {
  mainTitle: "A team you can rely on",
  title: "Personalized support and local service",
  content:
    "You give your customers personalized attention and advice. Don’t you deserve the same? Our expert team will set you up with a custom-designed solution that's teed up to help you reach all your goals with your sporting goods business needs. And, you can be confident that we'll always be there for you with our local service and 24/7 technical support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/retail/sporting-goods/demo",
  },
};

export const sportingLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "Tech that plays well together",
      blockSubTitle:
        "Forget about incompatible software, mismatched catalogs, and slow payment solutions. Our cloud-based tools work together, with a single login—from a computer or your mobile phone. Plus, our pricing is transparent, without hidden fees or long-term commitments. Get a suite of easy-to-use technology that works together, from a trusted partner that’s looking out for your bottom line.",
      blockImg: "sporting-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/sporting-goods/demo",
      },
    },
  ],
};

export const sportingTrusted = [
  {
    title: "72%",
    subtext:
      "of Americans prefer to buy from an independent store over a national retailer if equally convenient and reliable",
  },
  {
    title: "78%",
    subtext:
      "of Americans prefer to purchase from a retailer offering both in-person and online options",
  },
  {
    title: "68%",
    subtext:
      "of Americans prefer to buy gifts from an independent store instead of a national retailer if delivered to their doorstep",
  },
  {
    title: "73%",
    subtext:
      "of Americans would buy more last-minute gifts online from independent stores if they had guaranteed delivery dates",
  },
];

export const sportingMultiSection = {
  sectionTitle: "Winning tools for sporting goods stores",
  featureBlocks: [
    {
      blockTitle: "In-store. Online. \n All-in-one POS",
      blockSubTitle:
        "Sell in-store and online more efficiently with SpotOn Retail's omnichannel catalog. Update it once and publish to both your in-store POS and your online store—saving you time and helping you reach more customers to increase sales.",
      blockList: [
        "Your choice of sleek in-store POS device for speedy checkouts",
        "Intuitive online storefront to make it easy for customers to buy online",
        "Multiple ways for customers to pay, including Apple Pay and Google Pay",
        "Customer data capture for marketing",
        "Online dashboard for transaction data & sales patterns",
      ],
      blockImg: "sporting-b.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/sporting-goods/demo",
      },
    },
    {
      blockTitle: "Market like a pro",
      blockSubTitle:
        "Keep your customers up to date with seasonal sporting goods, send digital coupons for overstocked inventory, and easily see who your top spenders are. With SpotOn, it’s quick and easy thanks to our cloud-based dashboard, which you can access right from your mobile phone or online—wherever you are.",
      blockList: [
        "Send marketing emails",
        "Boost business with digital coupons",
        "Create Facebook campaigns to expand your reach",
      ],
      blockImg: "sporting-c.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/sporting-goods/demo",
      },
    },
    {
      blockTitle: "Beat the big guys - sell online, deliver fast",
      blockSubTitle:
        "Our team of pros will build an ecommerce store that’s easy for you and your customers. Do you offer specialty services like stringing tennis racquets or customizing team gear? You can add those services to your store, too. With built-in tools for shipping and local delivery, selling online has never been easier.",
      blockImg: "sporting-d.png",
      blockList: [
        "Reach new customers by selling your products and services online",
        "Offer same-day delivery with our affordable, flat-fee DoorDash integration",
        "Provide in-store pickup and local 2-day delivery options",
        "Ship nationwide with the lowest shipping rates available",
        "Optional loyalty rewards to help boost repeat business",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/sporting-goods/demo",
      },
    },
    {
      blockTitle: "Reward customers and increase your sales",
      blockSubTitle:
        "Give your sales a shot to the arm by offering customers custom loyalty rewards. Our integrated point-of-sale makes it fast and easy for your customers to enroll during checkout, earn “Spots” with every purchase, and redeem them for custom rewards of your choice.",
      blockImg: "sporting-e.png",
      blockList: [
        "Create custom rewards",
        "Enroll customers during checkout",
        "Enable automated email deals to encourage repeat visits",
        "Increase sales with every check-in and redemption",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/sporting-goods/demo",
      },
    },
    {
      blockTitle: "Manage reviews. Boost your reputation",
      blockSubTitle:
        "Outplay the competition by improving your online reputation. Our review management software makes it easy to monitor all your reviews from sites like Yelp, Google, and Facebook in one place. You also get automated alerts when new reviews are posted, so that you can not only monitor your reputation but improve it—right from your SpotOn Dashboard.",
      blockImg: "sporting-f.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/sporting-goods/demo",
      },
    },
  ],
};

export const sportingCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/retail/sporting-goods/demo",
  },
};

export const sportingTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const sportingArticles = {
  title: "Expert articles & advice on growing your business",
  bgText: "Articles.",
};

export const hairCar = {
  title: "Run a different type of retail business",
  subtext: "Sporting goods stores are just one of our specialties.",
  punctuation: "?",
  slides: [
    {
      bg: "clothing.png",
      cardTitle: "Clothing & Apparel",
      cardContent: "",
      icon: "clothing-icon.png",
      linkTo: "/retail/clothing-and-apparel",
    },
    {
      bg: "home-decor.png",
      cardTitle: "Home decor",
      cardContent: "",
      icon: "home-decor.png",
      linkTo: "/retail/home-decor",
    },
  ],
};
