// eslint-disable-next-line import/prefer-default-export
export const retailOverviewHero = {
  title: "In-store. Online. \n A retail POS system that's everywhere",
  subtext:
    "Save time and boost sales with SpotOn Retail, the omnichannel retail point-of-sale system that makes it easy to sell in-store and online—with one cloud-based catalog for your products and services.",
  heroImg: "",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/retail/demo",
  },
  video: {
    videoBtn: "Watch video",
    posterWrapperClass: "",
    posterImg: "retail_overview_thumbnail.png",
    videoUrlId: "4JgRGDnbrVM",
  },
};

export const retailOverviewTrusted = [
  {
    title: "72%",
    subtext:
      "of Americans prefer to buy from an independent store over a national retailer if equally convenient and reliable",
  },
  {
    title: "78%",
    subtext:
      "of Americans prefer to purchase from a retailer offering both in-person and online options",
  },
  {
    title: "68%",
    subtext:
      "of Americans prefer to buy gifts from an independent store instead of a national retailer if delivered to their doorstep",
  },
  {
    title: "73%",
    subtext:
      "of Americans would buy more last-minute gifts online from independent stores if they had guaranteed delivery dates",
  },
];

export const retailOverviewWhiteGlove = {
  mainTitle: "Top-tier service and support",
  title: `Custom setup. \n Personalized support`,
  content:
    "Your customers expect more than ever from your retail business—the ease of purchasing online, expert in-store advice, and more ways to pay. We’re here to help. Our team will work closely with you to create a custom cloud-based catalog, build your online store, and configure a slick point-of-sale device for in-store transactions, helping you save time and money while also reaching more customers. And we’ll continue to be there whenever you need us with a local, dedicated representative and 24/7 personalized support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/retail/demo",
  },
};
export const retailOverviewSubverticals = {
  title: "Just a few of our retail specialties",
  subverticalInfo: [
    {
      target: "/retail/clothing-and-apparel",
      iconImg: "clothing-icon.png",
      subverticalName: "Clothing & apparel",
      subverticalContent:
        "Sell in style with an integrated retail management system that makes it easy to stay connected with customers online and in-person.",
    },
    {
      target: "/retail/sporting-goods",
      iconImg: "sporting.png",
      subverticalName: "Sporting goods",
      subverticalContent:
        "Kick your game up a notch with high-performance tech to sell online and in-store, from one easily manageable catalog.",
    },
    {
      target: "/retail/home-decor",
      iconImg: "home-decor.png",
      subverticalName: "Home decor",
      subverticalContent:
        "Simplify how you sell with one system to sell both goods and services, so you can spend more time delighting your customers.",
    },
  ],
};

export const retailOverviewMultiSection = {
  sectionTitle: "Sell better. Wherever your customers are",
  featureBlocks: [
    {
      blockTitle: "One catalog. One reporting system. One transparent price",
      blockSubTitle:
        "Say goodbye to having 2 different systems for point-of-sale and ecommerce. With SpotOn Retail’s omnichannel catalog, you can easily create and update your catalog for selling both in-store and online. Change one and be done. At the same time, grow revenue by giving your customers more ways to shop with you rather than a competitor.",
      blockImg: "retail-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/demo",
      },
    },
    {
      blockTitle: "Point-of-sale built for retail",
      blockSubTitle:
        "Transform the checkout process into the beginning of a life-long customer relationship. Choose between a sleek countertop device or cut the wires with our popular handheld POS. Both options make it easy to build your customer list and get the real-time data you need to make smarter business decisions.",
      blockList: [
        "Intuitive catalog navigation for fast checkout",
        "Apple Pay, Google Pay, PayPal, Venmo, and NFC enabled",
        "Customer data capture for marketing",
        "Online dashboard for transaction data & analytics",
      ],
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/demo",
      },
      blockImg: "retail-b.png",
      forceReverse: false,
    },
    {
      blockTitle: "Beat the big-box retailers \n with a custom online store",
      blockSubTitle:
        "Increase sales with a hassle-free ecommerce store with built-in tools for shipping and local delivery. Our experts will do all the heavy lifting—and even build a new custom website if you need one. All you have to do is choose the products you want to sell online from your cloud-based catalog and publish them to your digital storefront.",
      blockList: [
        "Reach new customers online",
        "Offer same-day delivery with our affordable, flat-fee DoorDash integration",
        "Provide curbside pickup and local 2-day delivery options",
        "Ship nationwide with the lowest shipping rates available",
        "Optional loyalty rewards to help boost repeat business",
      ],
      blockImg: "retail-f.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/demo",
      },
    },
    {
      blockTitle: "Stay connected with your customers",
      blockSubTitle:
        "SpotOn Retail includes powerful, integrated tools to run and grow your retail business from a single online dashboard. Jump from viewing hourly sales trends to sending a digital coupon to customers with a click of a button. The SpotOn Dashboard lets you do it all—from any computer or mobile device.",
      blockList: [
        "Send digital coupons",
        "Send marketing emails",
        "Create Facebook campaigns",
        "Monitor your online reputation on Yelp, Google, and TripAdvisor",
      ],
      blockImg: "retail-c.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/retail/demo",
      },
    },
  ],
};
export const retailOverviewPartIntegration = {
  title: "Looking for a different point-of-sale solution",
  punctuation: "?",
  integrationsContent: [
    {
      title: "SpotOn Terminal",
      subtext:
        "Get personal with the all-in-one smart device for payments, marketing, and customer insights.",
      contentImg: "terminal.png",
      ImgClassName: "register",
      btnInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/terminal",
      },
    },
    {
      title: "SpotOn Virtual Terminal",
      subtext:
        "Run payments, send invoices, and set up recurring payment plans right from your computer.",
      contentImg: "virtual-terminal.png",
      ImgClassName: "phone",
      btnInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/virtual-terminal",
      },
    },
  ],
};

export const retailOverviewCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/retail/demo",
  },
};

export const retailOverviewVideo = {
  title: "What can SpotOn do for your business",
  videoBtn: "Watch video",
  posterImg: "retail-poster.png",
  videoUrlId: "4JgRGDnbrVM",
  punctuation: "?",
};

export const retailIntegrations = {
  title: "Built to work with the software you love",
  subtext:
    "Have software or online tools you already use and love? Our flexible retail platform is designed to integrate with other software and systems so it works for you—not the other way around.",
};

export const retailOverviewTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const retailOverviewArticles = {
  title: "Expert advice on growing your retail business",
  bgText: "Articles.",
};

export const retailByTheNumbers = {
  title: "By the numbers",
  pie: {
    title: "Grow",
    subTitle: "with the right tools",
  },

  leftFacts: [
    {
      title: "40%",
      subTitle: "decrease in no-shows with automated reminders",
    },
    {
      title: "31% ",
      subTitle: "increase in sales with repeat customers",
    },
  ],

  rightFacts: [
    {
      title: "88%",
      subTitle:
        "customers trust online reviews as much as personal recommendations",
    },
    {
      title: "30 – 40%",
      subTitle: "increase in sales with online booking",
    },
  ],
};

export const retailOverviewFaqs = {
  title: "Frequently Asked Questions.",
  ctaPrime: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};
