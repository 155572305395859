import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import BHero from "../../../components/Hero/b-hero";
import {
  sportingHero,
  sportingSubHero,
  sportingWhiteGlove,
  sportingLargeFeatureA,
  sportingTrusted,
  sportingMultiSection,
  sportingCTA,
  sportingTestimonials,
  sportingArticles,
  hairCar,
} from "../../../data/subverticals/retail/sporting-goods-data";
// import NoVisualsCustom from "../../../components/NoVisuals/NoVisualsCustom";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";
// import Articles from "../../../components/Articles/Articles";
// import VideoSection from "../../../components/VideoSection/VideoSection";
import heroBg from "../../../images/hero-images/sporting-hero.png";
import { retailOverviewVideo } from "../../../data/subverticals/retail/retail-overview-data";

const NoVisualsCustom = loadable(() =>
  import("../../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);
const Articles = loadable(() =>
  import("../../../components/Articles/Articles")
);
const VideoSection = loadable(() =>
  import("../../../components/VideoSection/VideoSection")
);

const whiteGloveImport = "sporting-white-glove.jpg";

const Sporting = () => {
  return (
    <Layout>
      <SEO
        title="Sporting Goods Store Point of Sale (POS) System"
        description="Every business needs a POS system that can sell & track online and in-store through one system. Get a free online demo today."
        image={`https://spoton.com/${heroBg}`}
      />
      <BHero sectionData={sportingHero} heroBg="sporting-hero.png" />
      <NoVisualsCustom sectionData={sportingSubHero} complex />
      <WhiteGlove
        sectionData={sportingWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={sportingLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers title="By the numbers" numbersArray={sportingTrusted} />
      <LargeFeatures
        sectionData={sportingMultiSection}
        style={{ marginTop: "128px" }}
      />
      <VideoSection sectionData={retailOverviewVideo} />
      <LargeCta sectionData={sportingCTA} />
      {/* <SuccessStories sectionData={heroSuccess} slides={hbBTSlides} /> */}
      <TestmonialReviews sectionData={sportingTestimonials} />
      <Articles
        sectionData={sportingArticles}
        blogFilters={{
          limit: 3,
          include: "tags,authors",
          filter: "tag:small-business",
        }}
        cornerStone={false}
        style={{ marginTop: 60 }}
      />
      <BusinessTypes sectionData={hairCar} />
      {/* <Faqs sectionData={sportingFaqs} /> */}
    </Layout>
  );
};

export default Sporting;
